<template lang="pug">
    .page.patients-detail
        h2 Карта пациента
            .patients-detail__status(
                v-show="!pageLoading"
                :class="{active: patient.is_active}"
            )
            .patients-detail__links(v-show="!pageLoading")
                a(
                    title="Выгрузка в PDF"
                    @click="exportPatientPdf"
                )
                    pdf-icon
                a(
                    title="Распечатать"
                    @click="printPatientPdf"
                )
                    print-icon
        app-loader(v-show="pageLoading")
        div(v-show="!pageLoading")
            .patients-detail__block.patients-detail__block--common
                div
                    h6 {{ patientName }}
                    .label.label--grey ID {{ patient.id }}
                    p {{ patientSex }}, {{ patientAge }} {{ getYearWord(patientAge) }}, {{ patientRace }}. На момент включения {{ patient.age_at_start }} {{ getYearWord(patient.age_at_start)}}.
                    p {{ patient.living_address }}, {{ patientPhone }}
                div
                    .row
                        .col
                            .label ОМС
                            p.fw600 {{ patient.oms }}
                        .col
                            .label Страхователь
                            p.fw600 {{ patient.insurance_company }}
                        .col
                            .label Направлен (-а) из
                            p.fw600 {{ patient.sent_from }}
                    .row(v-if="patient.note")
                        .col
                            .label Примечание
                            p(v-html="getNote()")


            .patients-detail__block
                .row
                    .col
                        .label Основные заболевания
                        p.fw600(v-if="!isEmpty(dermatitis)") Атопический дерматит
                        p.fw600(v-if="!isEmpty(asthma)") Бронхиальная астма
                        p.fw600(v-if="!isEmpty(hives)") Хроническая крапивница
                    .col
                        .label Последний контакт
                        p.fw600 {{ patientLastContact }}

                template(v-if="!isEmpty(dermatitis)")
                    h4 Атопический дерматит
                    .row
                        .col.col--diagnosis
                            .label Диагноз
                            p.fw600(v-html="getLastDiagnosis(dermatitis.diagnoses).formatted.data")
                        .col
                            .label Дата установки
                            p.fw600 {{ getLastDiagnosis(dermatitis.diagnoses).formatted.diagnosis_date }}
                        .col
                            .label Дата возникновения первых симптомов
                            p.fw600 {{ getDiagnosesFirstSymptomsDate(dermatitis.diagnoses) }}

                    .row(v-if="getUnfinishedTherapies(dermatitis.target_therapies).length")
                        .col
                            .label Таргетная терапия
                            p.fw600(v-for="therapy in getUnfinishedTherapies(dermatitis.target_therapies)") {{ getTargetTherapyString(therapy) }}

                    .row(v-if="getUnfinishedTherapies(dermatitis.basis_therapies).length")
                        .col
                            .label Базисная терапия
                            p.fw600(v-for="therapy in getUnfinishedTherapies(dermatitis.basis_therapies)") {{ getBasicTherapyString(therapy) }}

                    .row(v-if="!isEmpty(dermatitis.exacerbations)")
                        .col
                            .label Последнее обострение
                            p.fw600(v-html="getLastExacerbationString(dermatitis.exacerbations)")

                    .row(v-if="!isEmpty(dermatitis.unwanted_effects)")
                        .col
                            .label Нежелательные эффекты
                            p.fw600(
                                v-for="effect in dermatitis.unwanted_effects"
                                v-html="getUnwantedEffectsString(effect)"
                            )

                template(v-if="!isEmpty(asthma)")
                    h4 Бронхиальная астма
                    .row
                        .col.col--diagnosis
                            .label Диагноз
                            p.fw600
                                span(v-html="getLastDiagnosis(asthma.diagnoses).formatted.data")
                                br
                                br
                                | Форма бронхиальной астмы {{ getAsthmaForms(getLastDiagnosis(asthma.diagnoses)) }}.
                        .col
                            .label Дата установки
                            p.fw600 {{ getLastDiagnosis(asthma.diagnoses).formatted.diagnosis_date }}
                        .col
                            .label Дата возникновения первых симптомов
                            p.fw600 {{ getDiagnosesFirstSymptomsDate(asthma.diagnoses) }}

                    .row(v-if="getUnfinishedTherapies(asthma.target_therapies).length")
                        .col
                            .label Таргетная терапия
                            p.fw600(v-for="therapy in getUnfinishedTherapies(asthma.target_therapies)") {{ getTargetTherapyString(therapy) }}

                    .row(v-if="getUnfinishedTherapies(asthma.basis_therapies).length")
                        .col
                            .label Базисная терапия
                            p.fw600(v-for="therapy in getUnfinishedTherapies(asthma.basis_therapies)") {{ getBasicTherapyString(therapy) }}

                    .row(v-if="!isEmpty(asthma.exacerbations)")
                        .col
                            .label Последнее обострение
                            p.fw600(v-html="getLastExacerbationString(asthma.exacerbations)")

                    .row(v-if="!isEmpty(asthma.unwanted_effects)")
                        .col
                            .label Нежелательные эффекты
                            p.fw600(
                                v-for="effect in asthma.unwanted_effects"
                                v-html="getUnwantedEffectsString(effect)"
                            )
                template(v-if="!isEmpty(hives)")
                    h4 Хроническая крапивница
                    .row
                        .col.col--diagnosis
                            .label Диагноз
                            p.fw600
                                span(v-html="getLastDiagnosis(hives.diagnoses).formatted.data")
                                br
                                | {{ getHivesString(hives.urticaria_option) }}
                            p.fw600(v-if="!isEmpty(hivesAngiooteki)")
                                span Ангиоотеки
                                span , дата дебюта - {{ moment(hivesAngiooteki.diagnoses[0].diagnosis_date).format(hivesAngiooteki.diagnoses[0].diagnosis_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }}
                                span , последний отек - {{ moment(hivesAngiooteki.angiootek_option.last_edema_at).format('DD.MM.YYYY') }}

                        .col
                            .label Дата установки
                            p.fw600 {{ getLastDiagnosis(hives.diagnoses).formatted.diagnosis_date }}
                        .col
                            .label Дата возникновения первых симптомов
                            p.fw600 {{ getDiagnosesFirstSymptomsDate(hives.diagnoses) }}

                    .row(v-if="getUnfinishedTherapies(hives.target_therapies).length")
                        .col
                            .label Таргетная терапия
                            p.fw600(v-for="therapy in getUnfinishedTherapies(hives.target_therapies)") {{ getTargetTherapyString(therapy) }}

                    .row(v-if="getUnfinishedTherapies(hives.basis_therapies).length")
                        .col
                            .label Базисная терапия
                            p.fw600(v-for="therapy in getUnfinishedTherapies(hives.basis_therapies)") {{ getBasicTherapyString(therapy) }}

                    .row(v-if="!isEmpty(hives.exacerbations)")
                        .col
                            .label Последнее обострение
                            p.fw600(v-html="getLastExacerbationString(hives.exacerbations)")

                    .row(v-if="!isEmpty(hives.unwanted_effects)")
                        .col
                            .label Нежелательные эффекты
                            p.fw600(
                                v-for="effect in hives.unwanted_effects"
                                v-html="getUnwantedEffectsString(effect)"
                            )


                template(v-if="showAllergoanamnezBlock")
                    h4 Аллергоанамнез
                    .row(v-if="allergoanamnez.sensitive_everyday || allergoanamnez.sensitive_epiderm || allergoanamnez.sensitive_pollen || allergoanamnez.sensitive_mushrooms || allergoanamnez.sensitive_food")
                        .col
                            .label Сенсибилизация
                            p.fw600
                                span(v-if="allergoanamnez.sensitive_everyday") К бытовым аллергенам
                                    br
                                span(v-if="allergoanamnez.sensitive_epiderm")  К эпидермальным аллергенам
                                    br
                                span(v-if="allergoanamnez.sensitive_pollen") К пыльцевым аллергенам
                                    br
                                span(v-if="allergoanamnez.sensitive_mushrooms") К аллергенам непатогенных плесневых грибов
                                    br
                                span(v-if="allergoanamnez.sensitive_food") К пищевым аллергенам
                    .row(v-if="!isEmpty(allergoanamnez.allergens)")
                        .col
                            .label Аллергены и проявления
                            p.fw600
                                template(v-for="allergen in allergoanamnez.allergens")
                                    span {{ allergen.allergen ? allergen.allergen.component : allergen.allergen_custom }}
                                    span(v-if="allergen.reactions") &nbsp;-&nbsp;{{ allergen.reactions }}
                                    span(v-if="allergen.year") &nbsp;|&nbsp;{{ allergen.year }} год
                                    br
                    .row(v-if="!isEmpty(allergoanamnez.sick_relatives)")
                        .col(v-if="!isEmpty(allergoanamnez.sick_relatives.filter(r => r.type === 'TYPE_DEFAULT'))")
                            .label Наследственность по аллергологии
                            p.fw600
                                span(v-for="sick_relative in allergoanamnez.sick_relatives.filter(r => r.type === 'TYPE_DEFAULT')")
                                    | {{ sick_relative.relative.name }} - {{ sick_relative.illness.name }}
                                    br
                        .col(v-if="!isEmpty(allergoanamnez.sick_relatives.filter(r => r.type === 'TYPE_AUTOIMMUNE'))")
                            .label Наследственность по аутоиммунным заболеваниям
                            p.fw600
                                span(v-for="sick_relative in allergoanamnez.sick_relatives.filter(r => r.type === 'TYPE_AUTOIMMUNE')")
                                    | {{ sick_relative.relative.name }} - {{ sick_relative.illness.name }}
                                    br

                    .row(v-if="!isEmpty(allergoanamnez.npvs)")
                        .col
                            .label НПВС и проявления
                            p.fw600
                                template(v-for="npvs in allergoanamnez.npvs")
                                    span {{ npvs.medicine.chemistry_name }}
                                    span(v-if="npvs.reactions") &nbsp;-&nbsp;{{ npvs.reactions }}
                                    span(v-if="npvs.year") &nbsp;|&nbsp;{{ npvs.year }} год
                                    br

                    .row(v-if="!isEmpty(allergoanamnez.medicines)")
                        .col
                            .label Лекарственные препараты и проявления
                            p.fw600
                                template(v-for="medicine in allergoanamnez.medicines")
                                    span {{ medicine.medicine.chemistry_name }}
                                    span(v-if="medicine.reactions") &nbsp;-&nbsp;{{ medicine.reactions }}
                                    span(v-if="medicine.year") &nbsp;|&nbsp;{{ medicine.year }} год
                                    br

                    .row(v-if="!isEmpty(allergoanamnez.food_allergies)")
                        .col
                            .label Пищевая аллергия
                            p.fw600
                                template(v-for="foodAllergy in allergoanamnez.food_allergies")
                                    span {{ getFoodAllergyType(foodAllergy) }}. Проявление: {{ foodAllergy.manifestations }}
                                    br

                template(v-if="showAnamnezBlock")
                    h4 Анамнез жизни
                    .row.row--anamnez
                        .col(v-if="!isEmpty(allergicRhinitis)")
                            .label Аллергический ринит
                            p.fw600 Да
                            template(v-if="!isEmpty(getUnfinishedTherapies(allergicRhinitis.basis_therapies))")
                                .label Терапия ринита
                                p.fw600(v-for="therapy in getUnfinishedTherapies(allergicRhinitis.basis_therapies)") {{ getBasicTherapyString(therapy) }}

                        .col(v-if="!isEmpty(allergicConjunctivitis)")
                            .label Аллергический конъюнктивит
                            p.fw600 Да
                            template(v-if="!isEmpty(getUnfinishedTherapies(allergicConjunctivitis.basis_therapies))")
                                .label Терапия конъюнктивита
                                p.fw600(v-for="therapy in getUnfinishedTherapies(allergicConjunctivitis.basis_therapies)") {{ getBasicTherapyString(therapy) }}

                        .col(v-if="!isEmpty(polypoidRhinosinusitis)")
                            .label Полипозный риносинусит
                            p.fw600 Да
                                br
                                span Количество полиэктомий - {{ polypoidRhinosinusitis.rhinosinusitis_option.polyectomia_count}}
                                template(v-if="polypoidRhinosinusitis.rhinosinusitis_option.last_polyectomia_at")
                                    br
                                    span Дата последней полиэктомии - {{ moment(polypoidRhinosinusitis.rhinosinusitis_option.last_polyectomia_at).format(polypoidRhinosinusitis.rhinosinusitis_option.last_polyectomia_at_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }}
                                template(v-if="polypoidRhinosinusitis.rhinosinusitis_option.last_kt_at")
                                    br
                                    span Последний КТ ППН - {{ moment(polypoidRhinosinusitis.rhinosinusitis_option.last_kt_at).format(polypoidRhinosinusitis.rhinosinusitis_option.last_kt_at_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }}
                            p.fw600(v-for="consultation in polypoidRhinosinusitis.consultations" :key="consultation.id")
                                | Заключение ЛОР-врача - {{ moment(consultation.date_at).format('DD.MM.YYYY') }}, {{ consultation.doctor_last_name }}, {{ consultation.data }}
                            template(v-if="!isEmpty(getUnfinishedTherapies(polypoidRhinosinusitis.basis_therapies))")
                                .label Терапия риносинусита
                                p.fw600(v-for="therapy in getUnfinishedTherapies(polypoidRhinosinusitis.basis_therapies)") {{ getBasicTherapyString(therapy) }}

                        .col(v-if="!isEmpty(gerb)")
                            .label ГЭРБ
                            p.fw600 Да
                            template(v-if="!isEmpty(getUnfinishedTherapies(gerb.basis_therapies))")
                                .label Терапия ГЭРБ
                                p.fw600(v-for="therapy in getUnfinishedTherapies(gerb.basis_therapies)") {{ getBasicTherapyString(therapy) }}

                        .col(v-if="!isEmpty(sideDermatitis)")
                            .label Атопический дерматит (сопутствующее)
                            p.fw600 Да. &nbsp;
                                | Дата появления первых симптомов - {{ moment(sideDermatitis.first_symptoms_at).format(sideDermatitis.first_symptoms_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }}.
                                | Дата установки диагноза - {{ moment(sideDermatitis.diagnoses[0].diagnosis_date).format(sideDermatitis.diagnoses[0].diagnosis_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }}

                        .col(v-if="!isEmpty(sideAsthma)")
                            .label Бронхиальная астма (сопутствующее)
                            p.fw600 Да. &nbsp;
                                | Дата появления первых симптомов - {{ moment(sideAsthma.first_symptoms_at).format(sideAsthma.first_symptoms_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }}.
                                | Дата установки диагноза - {{ moment(sideAsthma.diagnoses[0].diagnosis_date).format(sideAsthma.diagnoses[0].diagnosis_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }}

                        .col(v-if="!isEmpty(sideHives)")
                            .label Хроническая крапивница (сопутствующее)
                            p.fw600 Да. &nbsp;
                                span {{getHivesString(sideHives.urticaria_option, true)}}
                                | . Дата установки диагноза - {{ moment(sideHives.diagnoses[0].diagnosis_date).format(sideHives.diagnoses[0].diagnosis_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }}
                                template(v-if="sideHives.exacerbations.length")
                                    br
                                    span Последнее обострение:&nbsp;
                                    span(v-html="getLastExacerbationString(sideHives.exacerbations)")
                            template(v-if="!isEmpty(getUnfinishedTherapies(sideHives.basis_therapies))")
                                .label Обострение крапивницы
                                p.fw600(v-for="therapy in getUnfinishedTherapies(sideHives.basis_therapies)") {{ getBasicTherapyString(therapy) }}

                        .col(v-if="!isEmpty(angiooteki)")
                            .label Ангиоотеки (сопутствующее)
                            p.fw600 Да,&nbsp;
                                | дата дебюта - {{ moment(angiooteki.diagnoses[0].diagnosis_date).format(angiooteki.diagnoses[0].diagnosis_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }},
                                | последний отек - {{ moment(angiooteki.angiootek_option.last_edema_at).format('DD.MM.YYYY') }}

                        .col(v-if="!isEmpty(otherSicknesses)")
                            .label Иные хронические заболевания
                            p.fw600(v-html="otherSicknesses.map(o => o.illness.code+' - '+o.illness.name).join(', ')")

                        .col(v-if="!isEmpty(getUnfinishedTherapies(otherTherapies))")
                            .label Терапия других заболеваний
                            p.fw600(v-for="therapy in getUnfinishedTherapies(otherTherapies)") {{ getBasicTherapyString(therapy) }}

                        .col(v-if="!isEmpty(otherExacerbations)")
                            .label Последнее обострение других заболеваний
                            p.fw600(v-html="getLastExacerbationString(otherExacerbations)")

                        .col(v-if="!isEmpty(patient.sizes)")
                            .label Вес
                            p.fw600(v-html="getPatientSizes()")

                        .col(v-if="patient.height")
                            .label Рост
                            p.fw600 {{ patient.height }} см

                        .col(v-if="patient.smoking")
                            .label Курение
                            p.fw600 Стаж курения - {{ patient.smoking.experience }} {{ getYearWord(patient.smoking.experience) }}
                                | , сигарет в день - {{ patient.smoking.cigarettes_a_day }}, индекс курильщика - {{ patient.smoking.smoker_index }}
                                span(v-if="patient.smoking.give_up_smoking_date") . Отказ от курения - {{ moment(patient.smoking.give_up_smoking_date).format('DD.MM.YYYY') }}

                    .col(v-if="patient.life_anamnez_other")
                        .label Другое
                        p.fw600(v-html="patient.life_anamnez_other.replace(/\\n/g, '<br />')")


            .patients-detail__block.patients-detail__block--tabs
                app-horizontal-tabs(
                    v-model="activeTabId"
                    :tabs="tabs"
                )
                .patients-detail-course(v-show="activeTabId === 0")
                    app-choice(
                        v-model="showContacts"
                        type="checkbox"
                        label="Показывать контакты"
                    )
                    .table
                        .table-tr
                            .table-tr__row
                                .table-th Дата
                                .table-th Заболевание
                                .table-th Событие
                                .table-th Описание

                        .table-tr(v-for="data in groupedHistory")
                            template(v-for="(group, groupIndex) in data.groups")
                                .table-tr__row(v-for="(item, itemIndex) in group.items")
                                    .table-td {{ groupIndex === 0 && itemIndex === 0 ? data.date : '' }}
                                    .table-td {{ itemIndex === 0 ? group.groupName : '' }}
                                    .table-td(v-html="getHistoryEvent(item)")
                                    .table-td(v-html="getHistoryDescription(item)")

                .patients-detail-dynamics(v-show="activeTabId === 1")
                    app-select.forMobile_or_p(
                        v-model="dynamicsPeriod"
                        :options="periodsList"
                    )
                    .table
                        .table__fixed
                            app-select.forDesktop(
                                v-model="dynamicsPeriod"
                                :options="periodsList"
                            )
                            .table-block__head.forMobile_or_p
                                .table-block__head-row
                                .table-block__head-row
                                    .table-block__head-col
                            template(v-for="group in groupedIndicatorsDynamic.indicators")
                                .table-tr.fw600
                                    .table-td {{ group.groupName }}
                                .table-tr(v-for="subGroup in group.groupItems")
                                    .table-td {{ subGroup.subGroupName }}
                        .table__scrolling-wrapper
                            .table__scrolling
                                .table-block(v-for="(dates, year) in groupedIndicatorsDynamic.dates")
                                    .table-block__head
                                        .table-block__head-row
                                            span {{ year }}
                                        .table-block__head-row
                                            .table-block__head-col(v-for="date in dates") {{ date.formatted }}
                                    .table-block__body
                                        template(v-for="group in groupedIndicatorsDynamic.indicators")
                                            .table-tr.fw600
                                                .table-td
                                            .table-tr(v-for="subGroup in group.groupItems")
                                                .table-td(v-for="date in dates")
                                                    | {{ getIndicatorByDate(date.date, subGroup.subGroupItems) }}
                                                    v-popover.table-td__details(
                                                        v-if="getIndicatorDetailsByDate(date.date, subGroup.subGroupItems)"
                                                        placement="top-start"
                                                    )
                                                        question-icon
                                                        template(slot="popover")
                                                            span(v-html="getIndicatorDetailsByDate(date.date, subGroup.subGroupItems)")

                .patients-detail-specific(v-show="activeTabId === 2")
                    app-select.forMobile_or_p(
                        v-model="specificPeriod"
                        :options="periodsList"
                    )
                    .table
                        .table__fixed
                            app-select.forDesktop(
                                v-model="specificPeriod"
                                :options="periodsList"
                            )
                            .table-block__head.forMobile_or_p
                                .table-block__head-row
                                .table-block__head-row
                                    .table-block__head-col
                            template(v-for="group in groupedSpecificExaminations.indicators")
                                .table-tr.fw600
                                    .table-td {{ group.groupName }}
                                .table-tr(v-for="subGroup in group.groupItems")
                                    .table-td {{ subGroup.subGroupName }}
                        .table__scrolling-wrapper
                            .table__scrolling
                                .table-block(v-for="(dates, year) in groupedSpecificExaminations.dates")
                                    .table-block__head
                                        .table-block__head-row
                                            span {{ year }}
                                        .table-block__head-row
                                            .table-block__head-col(v-for="date in dates") {{ date.formatted }}
                                    .table-block__body
                                        template(v-for="group in groupedSpecificExaminations.indicators")
                                            .table-tr.fw600
                                                .table-td
                                            .table-tr(v-for="subGroup in group.groupItems")
                                                .table-td(v-for="date in dates") {{ getIndicatorByDate(date.date, subGroup.subGroupItems) }}



            .patients-detail__block.patients-detail__block--buttons
                app-button(
                    tag="router-link"
                    :to="{name: 'PatientsReContact', params: { id: patient.id }}"
                    large
                ) Добавить повторный контакт
                app-button(
                    tag="router-link"
                    :to="{name: 'PatientsEdit', params: { id: patient.id }}"
                    color="white"
                    large
                ) Редактировать карту
                app-link.app-link--left-margin(tag="router-link" :to="{ name: 'Patients' }") Вернуться к списку пациентов
</template>

<script>
import AppHorizontalTabs from '@/components/app/app-horizontal-tabs/index';
import pdfIcon from '@/assets/images/icons/pdf.svg';
import printIcon from '@/assets/images/icons/print.svg';
import questionIcon from '@/assets/images/icons/question.svg';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { CONTACT_TYPES, ASTHMA_FORMS, ILLNESSES, HIVES_TYPES, INDUCED_TYPES, FOOD_ALLERGIES_TYPES } from '@/js/const';
import getFIOAbbreviation from '@/js/utils/getFIOAbbreviation';
import downloadFile from '@/js/utils/downloadFile';
import print from 'print-js';

export default {
    name: 'patients-detail',

    components: {
        AppHorizontalTabs,
        pdfIcon,
        printIcon,
        questionIcon,
    },

    data: () => ({
        patient: {},

        dermatitis: {},
        asthma: {},
        hives: {},
        hivesAngiooteki: {},

        allergoanamnez: {},
        allergicRhinitis: {},
        allergicConjunctivitis: {},
        polypoidRhinosinusitis: {},
        gerb: {},
        sideDermatitis: {},
        sideAsthma: {},
        sideHives: {},
        angiooteki: {},
        otherSicknesses: {},
        otherTherapies: {},
        otherExacerbations: {},

        history: [],
        showContacts: true,

        indicatorsDynamic: [],

        specificExaminations: [],

        activeTabId: 0,
        tabs: [
            {
                id: 0,
                name: 'Течение заболевания',
            },
            {
                id: 1,
                name: 'Динамика показателей',
            },
            {
                id: 2,
                name: 'Специфические обследования',
            },
        ],

        dynamicsPeriod: {
            name: 'За все время',
            value: 1000000,
        },

        specificPeriod: {
            name: 'За все время',
            value: 1000000,
        },

        periodsList: [
            {
                name: '3 месяца',
                value: 3,
            },
            {
                name: 'Пол года',
                value: 6,
            },
            {
                name: 'Год',
                value: 12,
            },
            {
                name: 'За все время',
                value: 1000000,
            },
        ],

        pageLoading: false,
    }),

    computed: {
        patientName() {
            return `${this.patient.last_name} ${this.patient.first_name} ${this.patient.middle_name || ''}`;
        },

        patientSex() {
            return this.patient.sex === '1' ? 'Мужчина' : 'Женщина';
        },

        patientAge() {
            return moment().diff(this.patient.birth_date, 'years');
        },

        patientRace() {
            return this.patient.race?.name.toLowerCase();
        },

        patientPhone() {
            return this.patient.phone?.replace(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5 ');
        },

        patientLastContact() {
            if (isEmpty(this.patient)) return '';
            let lastContact = this.patient.contacts[0];
            this.patient.contacts.forEach(c => {
                if (+moment(c.date_at) >= +moment(lastContact?.date_at)) {
                    lastContact = c;
                }
            });

            const type = CONTACT_TYPES.find(c => c.id === lastContact?.contact_type)?.name;
            const date = moment(lastContact?.date_at).format('DD.MM.YYYY');

            return `${type} ${date}`;
        },

        showAllergoanamnezBlock() {
            return this.allergoanamnez?.sensitive_everyday
                || this.allergoanamnez?.sensitive_epiderm
                || this.allergoanamnez?.sensitive_pollen
                || this.allergoanamnez?.sensitive_mushrooms
                || this.allergoanamnez?.sensitive_food
                || !isEmpty(this.allergoanamnez?.allergens)
                || !isEmpty(this.allergoanamnez?.sick_relatives)
                || !isEmpty(this.allergoanamnez?.npvs)
                || !isEmpty(this.allergoanamnez.medicines);
        },

        showAnamnezBlock() {
            return !isEmpty(this.allergicRhinitis)
                || !isEmpty(this.allergicConjunctivitis)
                || !isEmpty(this.polypoidRhinosinusitis)
                || !isEmpty(this.gerb)
                || !isEmpty(this.sideDermatitis)
                || !isEmpty(this.sideAsthma)
                || !isEmpty(this.sideHives)
                || !isEmpty(this.angiooteki)
                || !isEmpty(this.otherSicknesses)
                || !isEmpty(this.otherExacerbations)
                || !isEmpty(this.patient?.sizes)
                || this.patient.height
                || this.patient.life_anamnez_other;
        },

        groupedHistory() {
            let history = this.history
                .filter(h => this.showContacts || h.type !== 'CONTACT')
                .reduce((total, h) => {
                    let isInaccurate = false;
                    if (h.data.diagnosis_date_inaccurate) {
                        isInaccurate = true;
                    }
                    const date = moment(h.date).format(isInaccurate ? 'MM.YYYY' : 'DD.MM.YYYY');
                    if (total[date]) {
                        total[date].push(h);
                    } else {
                        total[date] = [h];
                    }
                    return total;
                }, []);
            history = Object.keys(history).map(date => ({
                date,
                items: history[date],
            }));
            history.sort((d1, d2) => +moment(d2.date, 'DD.MM.YYYY') - +moment(d1.date, 'DD.MM.YYYY'));
            history.forEach(h => {
                h.items.sort((i1, i2) => {
                   if (this.getHistoryEvent(i1) > this.getHistoryEvent(i2)) return 1;
                   if (this.getHistoryEvent(i1) < this.getHistoryEvent(i2)) return -1;
                   return 0;
                });
            });
            // Разбиваем на группы
            history.forEach(h => {
                const groupedItems = [];
                h.items.forEach(item => {
                    const illness = this.getHistoryIllness(item);
                    const foundedGroup = groupedItems.find(g => g.groupName === illness);
                    if (foundedGroup) {
                        foundedGroup.items.push(item);
                    } else {
                        groupedItems.push({
                            groupName: illness,
                            items: [ item ],
                        });
                    }
                });
                groupedItems.sort((g1, g2) => {
                    return g1.groupName > g2.groupName ? 1 : g1.groupName < g2.groupName ? -1 : 0;
                });
                h.groups = groupedItems;
                delete h.items;
            });
            return history;
        },

        groupedIndicatorsDynamic() {
            let dates = new Set();

            let indicators = this.indicatorsDynamic?.reduce((total, i) => {
                const type = i.sickness?.type;
                if (i.type === 'tnss') {
                    total.push({
                        groupName: ILLNESSES['ALLERGIC_RHINITIS'],
                        name: 'TNSS',
                        value: i.result,
                        date: i.date_at,
                    });
                } else if (i.type === 'vas') {
                    total.push({
                        groupName: ILLNESSES['ALLERGIC_RHINITIS'],
                        name: 'ВАШ',
                        value: i.result,
                        date: i.date_at,
                    });
                } else if (type === 'POLYPOID_RHINOSINUSITIS') {
                    const problems = i.problems
                        .split(', \n')
                        .join('\n');

                    total.push({
                        groupName: ILLNESSES['POLYPOID_RHINOSINUSITIS'],
                        name: i.version === 'SNOT20' ? 'Snot 20' : (i.version === 'SNOT22' ? 'Snot 22' : 'ЧТО-ТО НЕПОНЯТНОЕ'),
                        value: i.result,
                        date: i.date_at,
                        details: problems,
                    });
                } else if (type === 'DERMATITIS'
                    || type === 'ASTHMA'
                    || type === 'URTICARIA_MAIN'
                    || type === 'SIDE_DERMATITIS'
                    || type === 'SIDE_ASTHMA'
                    || type === 'CHRONIC_URTICARIA'
                    || type === 'ANGIOOTEK'
                    || type === 'ANGIOOTEK_URTICARIA_MAIN') {
                    Object.entries(i.data).forEach(o => {
                        total.push({
                            groupName: ILLNESSES[type],
                            name: o[0],
                            value: o[1],
                            date: i.date_at,
                        });
                    });
                } else if (i.functional_test) {
                    total.push({
                        groupName: 'Функциональные тесты',
                        name: i.functional_test.name,
                        value: i.result,
                        date: i.date_at,
                        details: i.note,
                    });
                } else if (i.laboratory_indicator) {
                    total.push({
                        groupName: 'Лабораторные показатели',
                        name: i.laboratory_indicator.name,
                        value: i.result,
                        date: i.date_at,
                        details: i.note,
                    });
                } else if (i.provocative_test) {
                    total.push({
                        groupName: 'Провокационные тесты',
                        name: i.provocative_test.name,
                        value: i.result,
                        date: i.date_at,
                        details: i.note,
                    });
                }
                if (moment().diff(i.date_at, 'months') < this.dynamicsPeriod.value) {
                    dates.add(i.date_at);
                }

                return total;
            }, []);

            // Группируем по группам
            indicators = indicators.reduce((total, i) => {
                if (!total[i.groupName]) {
                    total[i.groupName] = [i];
                } else {
                    total[i.groupName].push(i);
                }
                return total;
            }, {});

            indicators = Object.keys(indicators).map(groupName => ({
                groupName,
                groupItems: indicators[groupName],
            }));
            indicators.sort((i1, i2) => {
                if (i1.groupName > i2.groupName) return 1;
                if (i1.groupName < i2.groupName) return -1;
                return 0;
            });

            // Группируем по именам в группах
            indicators.forEach(group => {
                group.groupItems = group.groupItems.reduce((total, i) => {
                    if (!total[i.name]) {
                        total[i.name] = [i];
                    } else {
                        total[i.name].push(i);
                    }
                    return total;
                }, {});
                group.groupItems = Object.keys(group.groupItems).map(subGroupName => ({
                    subGroupName,
                    subGroupItems: group.groupItems[subGroupName],
                }));
                group.groupItems.sort((i1, i2) => {
                    if (i1.subGroupName > i2.subGroupName) return 1;
                    if (i1.subGroupName < i2.subGroupName) return -1;
                    return 0;
                });
            });

            // Группируем даты по годам
            dates = Array.from(dates).reduce((total, d) => {
                const year = moment(d).format('YYYY');
                const formattedDate = {
                    date: d,
                    formatted: moment(d).format('DD.MM'),
                };
                if (!total[year]) {
                    total[year] = [formattedDate];
                } else {
                    total[year].push(formattedDate);
                }
                return total;
            }, {});
            // Сортируем даты по возрастанию
            Object.keys(dates).forEach(year => {
                dates[year].sort((d1, d2) => +moment(d1.date) - +moment(d2.date));
            });
            return {
                indicators,
                dates,
            };
        },

        groupedSpecificExaminations() {
            let dates = new Set();

            let indicators = this.specificExaminations?.reduce((total, i) => {
                total.push({
                    groupName: i.specific_examination.name,
                    name: i.allergen?.component || i.allergen_custom,
                    value: i.result,
                    date: i.date_at,
                });
                if (moment().diff(i.date_at, 'months') < this.specificPeriod.value) {
                    dates.add(i.date_at);
                }
                return total;
            }, []);

            // Группируем по группам
            indicators = indicators.reduce((total, i) => {
                if (!total[i.groupName]) {
                    total[i.groupName] = [i];
                } else {
                    total[i.groupName].push(i);
                }
                return total;
            }, {});

            indicators = Object.keys(indicators).map(groupName => ({
                groupName,
                groupItems: indicators[groupName],
            }));
            indicators.sort((i1, i2) => {
                if (i1.groupName > i2.groupName) return 1;
                if (i1.groupName < i2.groupName) return -1;
                return 0;
            });

            // Группируем по именам в группах
            indicators.forEach(group => {
                group.groupItems = group.groupItems.reduce((total, i) => {
                    if (!total[i.name]) {
                        total[i.name] = [i];
                    } else {
                        total[i.name].push(i);
                    }
                    return total;
                }, {});
                group.groupItems = Object.keys(group.groupItems).map(subGroupName => ({
                    subGroupName,
                    subGroupItems: group.groupItems[subGroupName],
                }));
                group.groupItems.sort((i1, i2) => {
                    if (i1.subGroupName > i2.subGroupName) return 1;
                    if (i1.subGroupName < i2.subGroupName) return -1;
                    return 0;
                });
            });

            // Группируем даты по годам
            dates = Array.from(dates).reduce((total, d) => {
                const year = moment(d).format('YYYY');
                const formattedDate = {
                    date: d,
                    formatted: moment(d).format('DD.MM'),
                };
                if (!total[year]) {
                    total[year] = [formattedDate];
                } else {
                    total[year].push(formattedDate);
                }
                return total;
            }, {});
            // Сортируем даты по возрастанию
            Object.keys(dates).forEach(year => {
                dates[year].sort((d1, d2) => +moment(d1.date) - +moment(d2.date));
            });
            return {
                indicators,
                dates,
            };
        },
    },

    created() {
        this.fetchPatient();
    },

    mounted() {
        if (!window.isMobileOrTablet) {
            window.Scrollbar.init(document.querySelector(`.patients-detail-dynamics .table__scrolling-wrapper`), {
                alwaysShowTracks: true,
            });
            window.Scrollbar.init(document.querySelector(`.patients-detail-specific .table__scrolling-wrapper`), {
                alwaysShowTracks: true,
            });
        }
        this.updateTableHeads();
    },

    beforeDestroy() {
        window.cancelAnimationFrame(window.requestAnimationFrameId);
    },

    methods: {
        updateTableHeads() {
            let tableHeads = document.querySelectorAll(`.table-block__head`);
            Array.from(tableHeads).forEach(tableHead => {
                let topOffset = tableHead.parentElement.getBoundingClientRect().top;
                if (topOffset > 0) {
                    topOffset = 0;
                }
                tableHead.style.transform = `translate3d(0,${-topOffset}px,0)`;
            });
            window.requestAnimationFrameId = window.requestAnimationFrame(this.updateTableHeads);
        },

        async fetchPatient() {
            this.pageLoading = true;
            try {
                const { data } = await this.axios.get(`patients/profile/${this.$route.params.id}`);
                this.patient = data.patient;

                this.dermatitis = data.patient.sicknesses.find(s => s.type === 'DERMATITIS') || {};
                this.asthma = data.patient.sicknesses.find(s => s.type === 'ASTHMA') || {};
                this.hives = data.patient.sicknesses.find(s => s.type === 'URTICARIA_MAIN') || {};

                this.hivesAngiooteki = data.patient.sicknesses.find(s => s.type === 'ANGIOOTEK_URTICARIA_MAIN') || {};

                this.allergoanamnez = data.patient.allergoanamnez;
                this.allergicRhinitis = data.patient.sicknesses.find(s => s.type === 'ALLERGIC_RHINITIS') || {};
                this.allergicConjunctivitis = data.patient.sicknesses.find(s => s.type === 'ALLERGIC_CONJUNCTIVITIS') || {};
                this.polypoidRhinosinusitis = data.patient.sicknesses.find(s => s.type === 'POLYPOID_RHINOSINUSITIS') || {};
                this.gerb = data.patient.sicknesses.find(s => s.type === 'GERB') || {};
                this.sideDermatitis = data.patient.sicknesses.find(s => s.type === 'SIDE_DERMATITIS') || {};
                this.sideAsthma = data.patient.sicknesses.find(s => s.type === 'SIDE_ASTHMA') || {};
                this.sideHives = data.patient.sicknesses.find(s => s.type === 'CHRONIC_URTICARIA') || {};
                this.angiooteki = data.patient.sicknesses.find(s => s.type === 'ANGIOOTEK') || {};
                this.otherSicknesses = data.patient.sicknesses.filter(s => s.type === 'OTHER');
                this.otherTherapies = data.patient.other_therapies;
                this.otherExacerbations = data.patient.other_exacerbations;

                this.history = data.history;
                this.indicatorsDynamic = data.indicatorsDynamic;
                this.specificExaminations = data.specificExaminations;
            } catch (e) {
                console.log(e);
                this.$router.replace('/not-found');
            } finally {
                this.pageLoading = false;
            }
        },

        async exportPatientPdf() {
            try {
                const { data } = await this.axios.get(`patients/profile/${this.patient.id}/export`, { responseType: 'blob' });
                downloadFile(data, 'Пациент', 'application/pdf');
            } catch (e) {
                console.log(e);
            }
        },

        async printPatientPdf() {
            try {
                const { data } = await this.axios.get(`patients/profile/${this.patient.id}/export`, { responseType: 'blob' });
                print(URL.createObjectURL(new Blob([data], {
                    type: 'application/pdf',
                })));
            } catch (e) {
                console.log(e);
            }
        },

        getLastDiagnosis(diagnoses) {
            let lastDiagnosis = diagnoses[0];
            diagnoses.forEach(d => {
                if (+moment(d.diagnosis_date) >= +moment(lastDiagnosis.diagnosis_date)) {
                    lastDiagnosis = d;
                }
            });
            return {
                ...lastDiagnosis,
                formatted: {
                    data: lastDiagnosis.data?.replace(/\n/g, '<br />') || '',
                    diagnosis_date: moment(lastDiagnosis.diagnosis_date).format(lastDiagnosis.diagnosis_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY'),
                },
            };
        },

        getDiagnosesFirstSymptomsDate(diagnoses) {
            const d = diagnoses.find(d => d.first_symptoms_date);
            if (d) {
                return moment(d.first_symptoms_date).format(d.first_symptoms_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY');
            }
            return null;
        },

        getAsthmaForms(diagnosis) {
            return diagnosis.asthma_forms.map(f => ASTHMA_FORMS.find(af => Number(af.id) === Number(f.id))?.name?.toLowerCase()).join(', ');
        },

        getUnfinishedTherapies(therapies) {
            // должны выводиться только поля без дат окончания
            return therapies.filter(t => !t.end_at);
        },

        getTargetTherapyString(therapy) {
            let str = `${therapy.drug?.name}`;
            let period = therapy.period?.name?.toLowerCase() === 'другая' ? therapy.period_custom.toLowerCase() : therapy.period?.name.toLowerCase();
            str += `, ${therapy.dose} ${therapy.measurement?.name}`;
            str += `, ${therapy.multiplicity} раз ${period}`;
            str += `, назначил ${getFIOAbbreviation(therapy.doctor_at_start)}`;
            str += ` (c ${moment(therapy.start_at).format('DD.MM.YYYY')})`;
            return str;
        },

        getBasicTherapyString(therapy) {
            let str = `${therapy.medicine?.chemistry_name}`;
            if (!therapy.dose_is_undefined) {
                let period = therapy.period?.name?.toLowerCase() === 'другая' ? therapy.period_custom.toLowerCase() : therapy.period?.name.toLowerCase();
                str += `, ${therapy.day_dose} ${therapy.measurement?.name} в сутки`;
                str += `, разовая доза ${therapy.once_dose} ${therapy.measurement?.name} ${therapy.multiplicity} раз ${period}`;
            }
            str += `, ${therapy.administration_method?.name?.toLowerCase()}`;
            str += ` (c ${moment(therapy.start_at).format('DD.MM.YYYY')})`;
            return str;
        },

        getExacerbationString(item) {
            let str = 'Госпитализация ';
            const hospitalization = item.hospitalization === 'HOSPITALIZATION_YES_ORIT' ? 'в ОРИТ'
                : item.hospitalization === 'HOSPITALIZATION_YES' ? '- да'
                    : '- нет';
            str += hospitalization;
            if (item.hospitalization !== 'HOSPITALIZATION_NO') {
                str += `, всего дней - ${item.hospitalization_days}`;
            }
            if (item.hospitalization === 'HOSPITALIZATION_YES_ORIT') {
                str += `, дней в ОРИТ - ${item.orit_days}`;
            }
            if (item.lpu) {
                str += `, ЛПУ - ${item.lpu}`;
            }
            if (item.sgks) {
                str += `, дней применения СКГС - ${item.sgks_days}`;
            } else {
                str += `, СКГС - нет`;
            }
            // выводим небулайзерную терапию только у бронхиальной астмы и других заболиваний
            if (item.sickness?.type === 'ASTHMA' || item.illnesses) {
                if (item.nebulizer_therapy) {
                    str += `, дней небулайзерной терапии - ${item.nebulizer_therapy_days}`;
                } else {
                    str += `, небулайзерная терапия - нет`;
                }
            }
            return str;
        },

        getLastExacerbationString(exacerbations) {
            if (!exacerbations.length) return '';
            let lastExacerbation = exacerbations[0];
            exacerbations.forEach(e => {
                if (+moment(e.start_at) >= +moment(lastExacerbation.start_at)) {
                    lastExacerbation = e;
                }
            });
            return this.getExacerbationString(lastExacerbation) +
                `<br>Дата обострения: ${moment(lastExacerbation.start_at).format(lastExacerbation.start_at_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY')}`;
        },

        getUnwantedEffectsString(effect) {
            let str = `Реакция ${effect.reaction.toLowerCase()}, `;
            if (effect.associated_with_drugs) {
                str += 'связана с препаратом: ';
            } else {
                str += 'не связана с препаратом: ';
            }
            str += `${effect.data} `;
            str += `(с ${moment(effect.date_at).format('DD.MM.YYYY')})`;
            return str;
        },

        getHivesString(hives, withFirstSymptomsDate=false) {
            const hivesType = HIVES_TYPES.find(h => h.id === hives.type);
            if (!hivesType) return '';

            let str = `${hivesType.name}`;

            if (hives.type === 'INDUCED' || hives.type === 'SPONTANEOUS_INDUCED') {
                str +=', ';
                str += Object.keys(INDUCED_TYPES)
                    .filter(i => hives[i])
                    .map(i => {
                        let typeStr = INDUCED_TYPES[i].name.toLowerCase();
                        const dateStart = hives[`${i}_date_start`];
                        const dateStartInaccurate = hives[`${i}_date_start_inaccurate`];
                        const dateEnd = hives[`${i}_date_end`];
                        const typeDates = [];
                        if (dateStart) {
                            typeDates.push(`дата начала - ${moment(dateStart).format(dateStartInaccurate ? 'MM.YYYY' : 'DD.MM.YYYY')}`);
                        }
                        if (dateEnd) {
                            typeDates.push(`дата окончания - ${moment(dateEnd).format('DD.MM.YYYY')}`);
                        }
                        if (typeDates.length) {
                            typeStr += ` (${typeDates.join(', ')})`;
                        }
                        return typeStr;
                    })
                    .join(', ');
            }
            if (withFirstSymptomsDate) {
                str += `. Дата возникновения первых симптомов - ${moment(hives.first_symptoms_at).format(hives.first_symptoms_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY')}`;
            }
            return str;
        },

        getPatientSizes() {
            if (!this.patient?.sizes?.length) {
                return '-';
            }
            let lastSizes = this.patient.sizes[0];
            this.patient.sizes.forEach(s => {
                if (+moment(s.date_at) >= +moment(lastSizes.date_at)) {
                    lastSizes = s;
                }
            });
            let str = `${lastSizes.weight} кг, `;
            str += `ИМТ ${lastSizes.body_mass_index}, `;
            str += `ожирение - ${lastSizes.obesity ? lastSizes.obesity+'&nbsp;степени' : 'нет'} `;
            str += `(на ${moment(lastSizes.date_at).format('DD.MM.YYYY')})`;
            return str;
        },

        getHistoryIllness(item) {
            if (item.type === 'DIAGNOSIS') {
                if (ILLNESSES[item.data.sickness.type]) {
                    return ILLNESSES[item.data.sickness.type];
                } else if (item.data.sickness.type === 'OTHER') {
                    return item.data.sickness.illness?.name;
                } else {
                    console.log(item);
                    return 'ЧТО-ТО НЕПОНЯТНОЕ';
                }
            } else if (item.type === 'TARGET_THERAPY_START'
                || item.type === 'TARGET_THERAPY_END'
                || item.type === 'BASIS_THERAPY_START'
                || item.type === 'BASIS_THERAPY_END') {
                if (ILLNESSES[item.data.sickness?.type]) {
                    return ILLNESSES[item.data.sickness?.type];
                } else {
                    return 'Терапия других заболеваний';
                }
            } else if (item.type === 'EXACERBATION') {
                if (ILLNESSES[item.data.sickness?.type]) {
                    return ILLNESSES[item.data.sickness?.type];
                } else if (item.data.illnesses) {
                    return item.data.illnesses.name;
                } else {
                    console.log(item);
                    return 'ЧТО-ТО НЕПОНЯТНОЕ';
                }
            } else if (item.type === 'EDEMA_LOCALIZATION') {
                return ILLNESSES[item.sicknessType];
            }
        },

        getHistoryEvent(item) {
            const types = {
                DIAGNOSIS: 'Диагноз',
                EXACERBATION: 'Обострение',
                TARGET_THERAPY_START: 'Назначена таргетная терапия',
                TARGET_THERAPY_END: 'Отменена таргетная терапия',
                BASIS_THERAPY_START: 'Назначена базисная терапия',
                BASIS_THERAPY_END: 'Отменена базисная терапия',
                SIZE: 'Вес',
                SPECIAL_PROCEDURE: 'КТ / Рентген',
                CONTACT: 'Контакт',
                EDEMA_LOCALIZATION: 'Локализация отеков',
            };
            if (item.type === 'CONTACT') {
                return CONTACT_TYPES.find(c => c.id === item.data.contact_type)?.name;
            }
            const sicknessType = item.data?.sickness?.type;
            if (sicknessType === 'ANGIOOTEK' || sicknessType === 'ANGIOOTEK_URTICARIA_MAIN') {
                return 'Дебют';
            }
            return types[item.type];
        },

        getHistoryDescription(item) {
            if (item.type === 'DIAGNOSIS') {
                const sicknessType = item.data?.sickness?.type;
                if (sicknessType === 'DERMATITIS' || sicknessType === 'ASTHMA' || sicknessType === 'URTICARIA_MAIN') {
                    let str = `${item.data.data}`;
                    const diagnoses = this.history.filter(h => h.type === item.type && h.data.sickness?.type === sicknessType).map(d => d.data);
                    str += `<br> Дата возникновения первых симптомов: ${this.getDiagnosesFirstSymptomsDate(diagnoses)}`;
                    /*if (item.data.first_symptoms_date) {
                        str += `<br> Дата возникновения первых симптомов: ${moment(item.data.first_symptoms_date).format(item.data.first_symptoms_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY')}`;
                    }*/
                    return str;
                }
                if (sicknessType === 'SIDE_DERMATITIS') {
                    return `Дата появления первых симптомов - ${ moment(this.sideDermatitis.first_symptoms_at).format(this.sideDermatitis.first_symptoms_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }`
                        + `, дата установки диагноза - ${ moment(this.sideDermatitis.diagnoses[0].diagnosis_date).format(this.sideDermatitis.diagnoses[0].diagnosis_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }`;
                }
                if (sicknessType === 'SIDE_ASTHMA') {
                    return `Дата появления первых симптомов - ${ moment(this.sideAsthma.first_symptoms_at).format(this.sideAsthma.first_symptoms_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }`
                        + `, дата установки диагноза - ${ moment(this.sideAsthma.diagnoses[0].diagnosis_date).format(this.sideAsthma.diagnoses[0].diagnosis_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY') }`;
                }
                if (sicknessType === 'CHRONIC_URTICARIA') {
                    return this.getHivesString(item.data.sickness?.urticaria_option, true);
                }
                if (sicknessType === 'ANGIOOTEK' || sicknessType === 'ANGIOOTEK_URTICARIA_MAIN') {
                    return `Дата - ${moment(item.data.diagnosis_date).format(item.data.diagnosis_date_inaccurate ? 'MM.YYYY' : 'DD.MM.YYYY')}`;
                }
            }
            if (item.type === 'CONTACT') {
                if (item.data.serum_taken) {
                    let serumStr = 'Взята сыворотка';
                    if (item.data.number) {
                        serumStr += ` №${item.data.number}`;
                    }
                    return serumStr;
                }
            }
            if (item.type === 'SIZE') {
                const obesity = item.data.obesity;
                return `<div class="table-td__col">Вес <br><i class="blue">${item.data.weight} кг</i></div>`
                    + `<div class="table-td__col">ИМТ <br><i class="blue">${item.data.body_mass_index}</i></div>`
                    + `<div class="table-td__col">Ожирение <br><i class="blue">${obesity ? obesity+' степени' : '-'}</i></div>`;
            }
            if (item.type === 'SPECIAL_PROCEDURE') {
                return `<div class="table-td__col">${item.data?.special_procedure?.name} <br><i class="blue">${item.data?.result}</i></div>`;
            }
            if (item.type === 'TARGET_THERAPY_START') {
                const drug = item.data.drug?.name;
                const dose = item.data.dose;
                const measurement = item.data.measurement?.name;
                const multiplicity = item.data.multiplicity;
                const period = item.data.period?.name?.toLowerCase() === 'другая' ? item.data.period_custom.toLowerCase() : item.data.period?.name.toLowerCase();
                return `${drug}, ${dose} ${measurement} х${multiplicity} ${period}  <br>`+
                    `<span>`+
                        `<i>назначил </i>`+
                        `<i class="blue">${getFIOAbbreviation(item.data.doctor_at_start)} </i>`+
                    `</span>`;
            }
            if (item.type === 'TARGET_THERAPY_END') {
                const drug = item.data.drug?.name;
                const dose = item.data.dose;
                const measurement = item.data.measurement?.name;
                const multiplicity = item.data.multiplicity;
                const period = item.data.period?.name?.toLowerCase() === 'другая' ? item.data.period_custom.toLowerCase() : item.data.period?.name.toLowerCase();
                const cancellationReason = item.data.cancellation_reason?.name?.toLowerCase();
                return `${drug}, ${dose} ${measurement} х${multiplicity} ${period} <br>`+
                    `<span>`+
                        `<i>назначил </i>`+
                        `<i class="blue">${getFIOAbbreviation(item.data.doctor_at_start)} </i>`+
                        `<i>отменил </i>`+
                        `<i class="blue">${getFIOAbbreviation(item.data.doctor_at_end)} - </i>`+
                        `<i class="red">${cancellationReason}</i>`+
                    `</span>`;
            }
            if (item.type === 'BASIS_THERAPY_START' || item.type === 'BASIS_THERAPY_END') {
                const medicine = item.data.medicine?.chemistry_name;
                const day_dose = item.data.day_dose;
                const once_dose = item.data.once_dose;
                const measurement = item.data.measurement?.name;
                const multiplicity = item.data.multiplicity;
                const period = item.data.period?.name?.toLowerCase() === 'другая' ? item.data.period_custom.toLowerCase() : item.data.period?.name.toLowerCase();
                const administrationMethod = item.data.administration_method?.name?.toLowerCase();
                let str = `${medicine}, `;
                if (!item.data.dose_is_undefined) {
                    str += `${day_dose} ${measurement} в сутки`;
                    str += `, разовая доза ${once_dose} ${measurement} х${multiplicity} ${period}`;
                } else {
                    str += `х${multiplicity} ${period}`;
                }
                str += `, ${administrationMethod}`;
                return str;
            }
            if (item.type === 'EDEMA_LOCALIZATION') {
                let str = '';
                const localizationsList = [
                    {
                        id: 'lips',
                        name: 'Губы',
                    },
                    {
                        id: 'tongue',
                        name: 'Язык',
                    },
                    {
                        id: 'pharynx',
                        name: 'Глотка',
                    },
                    {
                        id: 'larynx',
                        name: 'Гортань',
                    },
                    {
                        id: 'face_area',
                        name: 'Область лица',
                    },
                    {
                        id: 'genitals',
                        name: 'Половые органы',
                    },
                    {
                        id: 'hands_and_feets',
                        name: 'Кисти рук / стопы',
                    },
                ];
                const items = localizationsList
                    .filter(l => item.data[l.id])
                    .map(l => l.name);
                if (item.data.other) {
                    items.push(item.data.other);
                }
                str += items.join(', ');

                if (item.data.edema_count) {
                    str += `<br>Количество отеков в месяц - ${item.data.edema_count}`;
                }
                if (item.data.edema_duration) {
                    str += `<br>Длительность отеков - ${item.data.edema_duration}`;
                }
                return str;
            }
            if (item.type === 'EXACERBATION') {
                return this.getExacerbationString(item.data);
            }
        },

        getIndicatorByDate(date, items) {
            return items.find(i => i.date === date)?.value;
        },

        getIndicatorDetailsByDate(date, items) {
            return items.find(i => i.date === date)?.details?.replace(/\n/g, '<br>');
        },

        getNote() {
            return this.patient?.note?.replace(/\n/g, '<br>');
        },

        getYearWord(year) {
            const lastNum = year % 10;
            if (+year >= 11 && +year <=19) {
                return 'лет';
            } else if (lastNum === 1) {
                return 'год';
            } else if (lastNum >= 2 && lastNum <= 4) {
                return 'года';
            } else {
                return 'лет';
            }
        },

        getFoodAllergyType(foodAllergy) {
            return FOOD_ALLERGIES_TYPES.find(f => f.id === foodAllergy.type)?.name;
        },

        isEmpty,
        moment,
    },
};
</script>

<style lang="scss">
.patients-detail {
    h2 {
        display: flex;
        align-items: flex-start;
    }

    &__block {
        margin-bottom: rem(32px);
        padding: 1rem 1rem 0.5rem;

        background: #fff;
        border-radius: rem(8px);
        box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);
    }

    .row {
        margin-bottom: rem(24px);
    }

    .col {
        &:not(:last-child) {
            margin-right: rem(32px);
        }
    }

    p, .label {
        margin-bottom: rem(8px);
    }

    .label {
        color: rgba($dark-blue, 0.5);

        &--grey {
            color: $dark-grey;
        }
    }

    p {
        color: $dark;
    }

    /* ------------------------------------- */
    &__status {
        width: rem(14px);
        height: rem(14px);
        margin-top: 0.3rem;
        margin-left: 0.5rem;

        background: $red;
        border-radius: 50%;

        &.active {
            background: $green;
        }
    }

    &__links {
        display: flex;

        margin-left: auto;

        a {
            width: rem(24px);
            margin-left: rem(24px);

            cursor: pointer;

            svg {
                width: 100%;

                transition: fill .2s;
            }

            &:hover svg {
                fill: $dark-blue;
            }
        }
    }

    &__block--common {
        display: flex;
        justify-content: space-between;

        h6 {
            position: relative;

            margin: 0 0 rem(4px);

            font-size: rem(24px);
            line-height: rem(29px);
            font-weight: bold;
            color: $dark-blue;
        }
    }

    .col--diagnosis {
        width: 60%;
    }

    .row--anamnez {
        flex-wrap: wrap;

        margin-bottom: 0;
        .col {
            width: 25%;
            margin-right: 0;
            margin-bottom: 1.5rem;
            padding-right: 1rem;

            word-break: break-word;
        }
    }

    &__block--buttons {
        padding-bottom: 0;
    }

    &-course {
        .app-choice {
            margin: 0;
        }

        .table {
            &-tr {
                padding: rem(32px) 0;

                &:not(:last-child) {
                    border-bottom: 1px solid $grey;
                }

                &__row {
                    display: flex;

                    &:not(:last-child) {
                        margin-bottom: rem(10px);
                    }
                }
            }

            &-th, &-td {
                &:nth-of-type(1) {
                    width: 7.7%;
                }

                &:nth-of-type(2) {
                    width: 21%;
                }

                &:nth-of-type(3) {
                    width: 21%;
                }

                &:nth-of-type(4) {
                    width: 50.3%;
                }
            }

            &-th {
                color: $dark-grey;
            }

            &-td {
                line-height: 1.15;
                font-weight: bold;

                &__col {
                    display: inline-block;

                    margin-right: rem(16px);
                }

                i {
                    font-size: rem(14px);
                    font-weight: 400;
                    color: $dark-grey;

                    &.blue {
                        color: $dark-blue;
                    }

                    &.red {
                        color: $red;
                    }
                }
            }
        }
    }

    &-dynamics, &-specific {
        .table {
            display: grid;

            grid-template-columns: rem(360px) auto;

            padding-bottom: 0.5rem;

            &__fixed {
                .app-select {
                    width: rem(330px);
                    margin: 0;
                }

                .table-td {
                    padding-left: 1rem;
                }
            }

            &__scrolling {
                display: flex;
            }

            &-block {
                &__head {
                    &-row {
                        display: flex;

                        font-weight: 600;

                        &:first-child {
                            justify-content: center;
                            align-items: center;

                            height: rem(31px);

                            font-size: rem(20px);

                            background: $dark-grey;
                            border: 1px solid $dark;
                        }

                        &:last-child {
                            background: $light-blue;
                        }
                    }

                    &-col {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: rem(97px);
                        height: rem(25px);

                        border: 1px solid $dark-grey;

                        &:not(:last-child) {
                            border-right: none;
                        }
                    }
                }

                &__body .table-td {
                    justify-content: center;

                    width: rem(97px);
                }
            }

            &-tr {
                display: flex;

                &:not(:last-child) {
                    border-bottom: 1px solid $grey;
                }
            }

            &-td {
                display: flex;
                align-items: center;

                height: rem(59px);

                &__details {
                    margin-left: rem(6px);

                    .trigger {
                        display: flex !important;
                    }

                    svg {
                        width: rem(18px);

                        cursor: pointer;
                    }
                }
            }

            .scrollbar {
                &-track {
                    height: 2px;

                    background: $blue-light;
                }
                &-thumb {
                    height: 100%;

                    background:  $blue;
                }
            }
        }
    }

    &-dynamics .table-tr, &-specific .table-tr {
        &:last-child{
            border-bottom: 1px solid $grey;
        }

        &.fw600 {
            border-bottom: none;
        }

        &:not(.fw600) .table-td {
            height: rem(35px);
        }
    }

    @include mobile_or_P {
        &__block {
            margin-bottom: 1.5rem;
        }

        .row {
            margin-bottom: 0;
        }

        .col {
            width: 100%;
            margin-bottom: rem(24px);

            &:not(:last-child) {
                margin-right: initial;
            }
        }

        h4 {
            margin-top: 0;
        }

        /* ------------------------------------- */

        &__links {
            padding-right: 1rem;
        }

        &__block--common {
            flex-direction: column;

            .row {
                margin: 1.5rem 0 -1.5rem;
            }

            .col {
                width: 100%;
            }
        }

        .col--diagnosis {
            width: 100%;
        }

        .row--anamnez .col {
            width: 100%;
            padding-right: 0;
        }

        &__block--buttons {
            display: flex;
            flex-direction: column;
            align-items: center;

            .app-button {
                justify-content: center;

                width: 100%;
                max-width: 100vmin;
            }

            .app-link {
                margin-left: 0;
            }
        }

        .app-horizontal-tabs {
            &__item {
                width: rem(180px);
                padding: 0 1rem 0.55rem;
            }
        }

        &-course {
            .app-choice {
                margin-bottom: 1rem;
            }

            .table {
                margin-right: -1rem;
                margin-left: -1rem;
                padding-left: 1rem;
                overflow: auto;

                &-tr {
                    width: 56rem;
                    padding: 1rem 0;
                }

                &-th, &-td {
                    flex-shrink: 0;

                    &:nth-of-type(1) {
                        width: 7.2rem;
                    }

                    &:nth-of-type(2) {
                        width: 10.8rem;
                    }

                    &:nth-of-type(3) {
                        width: 17rem;
                    }

                    &:nth-of-type(4) {
                        width: 21rem;
                        padding-right: 1rem;
                    }
                }
            }
        }

        &-dynamics, &-specific {
            .app-select {
                margin-bottom: 2rem;
            }

            .table {
                grid-template-columns: rem(185px) auto;

                &__fixed {
                    font-size: rem(14px);

                    .table-block__head {
                        opacity: 0;
                    }
                }

                &__scrolling-wrapper {
                    overflow: auto;

                    box-shadow: inset rem(4px) 0 rem(10px) rgb(0 0 0 / 5%);
                }

                &-block__head-row:first-child {
                    justify-content: flex-start;

                    padding-right: 3.25rem;
                    padding-left: 3.25rem;

                    span {
                        position: sticky;
                        left: 3.25rem;
                    }
                }
            }
        }
    }
}
</style>
