<template lang="pug">
    .questionnaires-dermatitis(:class="{'questionnaires-dermatitis--re-contact': isReContact}")
        .questionnaires-dermatitis__row(
            v-for="(item, index) in questionnaires"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.questionnaires-dermatitis--date(
                    v-model="item.date"
                    label="Дата"
                    :disabled-dates="disabledDates"
                    :error="getFieldError(item, index, 'date')"
                    :disabled="additionOnly && item.old"
                )

                questionnaire-bsa(
                    v-model="item.BSA"
                    :error="getFieldError(item, index, 'BSA')"
                    :disabled="additionOnly && item.old && item.old_values.BSA"
                )

                questionnaire-scorad(
                    v-model="item.SCORAD"
                    :default-bsa="item.BSA"
                    :error="getFieldError(item, index, 'SCORAD')"
                    :disabled="additionOnly && item.old && item.old_values.SCORAD"
                )

                questionnaire-easi(
                    v-model="item.EASI"
                    :error="getFieldError(item, index, 'EASI')"
                    :disabled="additionOnly && item.old && item.old_values.EASI"
                )

                app-text-field.questionnaires-dermatitis--IGA(
                    v-model="item.IGA"
                    label="IGA"
                    is-number
                    :max="4"
                    :error="getFieldError(item, index, 'IGA')"
                    :disabled="additionOnly && item.old && item.old_values.IGA"
                )

                questionnaire-dlqi(
                    v-model="item.DLQI"
                    :error="getFieldError(item, index, 'DLQI')"
                    :disabled="additionOnly && item.old && item.old_values.DLQI"
                )

                questionnaire-poem(
                    v-model="item.POEM"
                    :error="getFieldError(item, index, 'POEM')"
                    :disabled="additionOnly && item.old && item.old_values.POEM"
                )

                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropQuestionnaires(index)"
                )
            hr.narrow(v-if="index < questionnaires.length - 1")
        .br
        app-button(
            plus
            @click="addNewQuestionnaires()"
        ) Добавить

</template>

<script>
import QuestionnaireScorad from '@/components/pages/patients/questionnaires/modals/SCORAD';
import QuestionnaireEasi from '@/components/pages/patients/questionnaires/modals/EASI';
import QuestionnaireDlqi from '@/components/pages/patients/questionnaires/modals/DLQI';
import QuestionnaireBsa from '@/components/pages/patients/questionnaires/modals/BSA';
import QuestionnairePoem from '@/components/pages/patients/questionnaires/modals/POEM';

import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

const requiredField = (value, item) => {
    return item.SCORAD || item.EASI || item.IGA || item.DLQI || item.POEM || item.BSA;
};

export default {
    name: 'questionnaires-dermatitis',

    components: {
        QuestionnaireScorad,
        QuestionnaireEasi,
        QuestionnaireDlqi,
        QuestionnairePoem,
        QuestionnaireBsa,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },

        hasDermatitis: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        questionnaires: [],
        openedQuestionnaire: null,

        canActualize: true,
    }),

    validations: {
        questionnaires: {
            $each: {
                date: { required },
                SCORAD: { required: requiredField },
                EASI: { required: requiredField },
                IGA: { required: requiredField },
                DLQI: { required: requiredField },
                POEM: { required: requiredField },
                BSA: { required: requiredField },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.questionnaires.map(q => q.date);
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.value.length) {
                this.questionnaires = this.value;
            }
            setTimeout(() => { // для того, чтобы при повторном контакте сначала добавились новые опросники, а потом уже ставился вотчер
                this.$eventBus.$on('actualizeQuestionnaires', this.actualizeQuestionnaires);
                this.$watch('questionnaires', (value) => {
                    this.$emit('input', value);
                    if (this.canActualize) {
                        this.$eventBus.$emit('actualizeQuestionnaires', this.questionnaires);
                    }
                }, { deep: true });
            });
        });
    },

    methods: {
        actualizeQuestionnaires(questionnaires) {
            if (!this.hasDermatitis) return;
            this.canActualize = false;
            questionnaires.forEach((q, ind) => {
                if (!this.questionnaires[ind]) {
                    this.addNewQuestionnaires();
                }
                this.questionnaires[ind].date = q.date;
                this.questionnaires[ind].DLQI = q.DLQI;
            });
            this.questionnaires = this.questionnaires.slice(0, questionnaires.length);
            setTimeout(() => {
                this.canActualize = true;
            });
        },

        addNewQuestionnaires(date = '') {
            this.questionnaires.push({
                id: uniqueId(),
                date: date,
                SCORAD: '',
                EASI: '',
                IGA: '',
                DLQI: '',
                POEM: '',
                BSA: '',
            });
            this.$v?.$reset();
        },

        dropQuestionnaires(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить опросник?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.questionnaires = this.questionnaires.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.questionnaires.$each.$iter[index].$dirty && !this.$v.questionnaires.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.questionnaires.map(d => ({
                date_at: moment(d.date).format('YYYY-MM-DD'),
                data: {
                    SCORAD: d.SCORAD,
                    EASI: d.EASI,
                    IGA: d.IGA,
                    DLQI: d.DLQI,
                    POEM: d.POEM,
                    BSA: d.BSA,
                },
            }));
        },

        getFormattedSavedFields(questionnaires) {
            let fields = questionnaires.map(d => {
                const obj = {
                    id: d.id,
                    date: +moment(d.date_at),
                    SCORAD: d.data.SCORAD || '',
                    EASI: d.data.EASI || '',
                    IGA: d.data.IGA || '',
                    DLQI: d.data.DLQI || '',
                    POEM: d.data.POEM || '',
                    BSA: d.data.BSA || '',
                    old: true,
                };
                return {
                    ...obj,
                    old_values: { ...obj },
                };
            });
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.questionnaires-dermatitis {
    .questionnaires-dermatitis {
        &--date {
            width: rem(168px);
        }

        &--IGA {
            width: rem(58px);
        }
    }

    &--re-contact {
        .questionnaires-dermatitis__row.old {
            display: none;
        }
    }

    @include mobile_or_P {
        .questionnaires-dermatitis {
            &--date {
                width: 100%;
            }

            &--IGA {
                width: rem(200px);
                margin-right: rem(16px);
            }
        }
    }
}
</style>
