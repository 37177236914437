<template lang="pug">
    .page.refresh
        .refresh__container
            .refresh__title Восстановление пароля
            form(@submit.prevent="submit")
                app-text-field(
                    v-model="email"
                    label="Ваш email"
                    :error="emailError"
                )
                app-text-field(
                    v-model="password"
                    label="Новый пароль"
                    type="password"
                    :error="passwordError"
                )
                .app-message-text.warning.complexity-error(v-if="$v.$dirty && !$v.password.complexity")
                    p Требования к паролю:
                    ul
                        li Минимальная длина - 8 символов;
                        li Минимум одна большая заглавная буква;
                        li Минимум одна маленькая буква;
                        li Минимум один знак (!,.@#%$^&*).
                app-text-field(
                    v-model="passwordConfirmation"
                    label="Повторите новый пароль"
                    type="password"
                    :error="passwordConfirmationError"
                )
                .app-message-text.warning.center(v-if="apiErrors.common" v-html="apiErrors.common")
                app-button(
                    type="submit"
                    :loading="loading"
                    large
                    center
                ) Подтвердить
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: 'refresh',

    data: () => ({
        email: '',
        password: '',
        passwordConfirmation: '',

        apiErrors: {},

        loading: false,
    }),

    validations: {
        email: {
            required,
            email,
        },

        password: {
            required,
            complexity(value) {
                if (value.length < 8) return false;
                if (!/[A-Z]/.test(value)) return false;
                if (!/[a-z]/.test(value)) return false;
                if (!/[(!,.@#%$^&*)]/.test(value)) return false;
                return true;
            },
        },

        passwordConfirmation: {
            required,
            matches(value) {
                return value === this.password;
            },
        },
    },

    computed: {
        emailError() {
            if (this.$v.$dirty) {
                if (!this.$v.email.required) {
                    return 'Введите ваш email';
                } else if (!this.$v.email.email) {
                    return 'Некорректный формат email';
                }
            }
            if (this.apiErrors.email) {
                return this.apiErrors.email;
            }
            return '';
        },

        passwordError() {
            if (this.$v.$dirty) {
                if (!this.$v.password.required) {
                    return 'Введите пароль';
                } else if (!this.$v.password.complexity) {
                    return 'Пароль не соответствует требованиям';
                }
            }
            return '';
        },

        passwordConfirmationError() {
            if (this.$v.$dirty) {
                if (!this.$v.passwordConfirmation.required) {
                    return 'Введите пароль';
                } else if (!this.$v.passwordConfirmation.matches) {
                    return 'Пароли не совпадают';
                }
            }
            return '';
        },
    },

    methods: {
        async submit() {
            if (this.loading) return;

            this.apiErrors = {};

            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            const fd = new FormData();
            fd.append('email', this.email);
            fd.append('password', this.password);
            fd.append('password_confirmation', this.passwordConfirmation);
            fd.append('token', this.$route.query.token);

            this.loading = true;

            try {
                await this.axios.post('auth/password-reset', fd);
                this.$router.replace('/auth');
            } catch (e) {
                if (e?.response?.status === 422) {
                    this.$set(this.apiErrors, 'common', 'Ссылка восставления пароля истекла или введен неверный Email адрес');
                } else {
                    this.$set(this.apiErrors, 'common', 'Что-то пошло не так, повторите попытку позже');
                }
            } finally {
                this.loading = false;
            }
        },
    },

};
</script>

<style lang="scss">
.refresh {
    display: flex;
    justify-content: center;
    align-items: center;

    height: inherit;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: rem(424px);
        max-width: 100%;
        padding: 2rem;

        background-color: $white;
        border-radius: rem(16px);
        box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);
    }

    &__title {
        margin-bottom: rem(48px);

        font-size: rem(24px);
        line-height: rem(29px);
        font-weight: bold;
        color: $dark-blue;
    }

    .app-text-field {
        margin-bottom: rem(24px);
    }

    .complexity-error {
        font-size: rem(12px);
    }

    .app-button {
        width: 100%;
        margin: 0;
    }

    @include mobile_or_P {
        &__container {
            padding: 1rem;
        }

        @include landscape {
            align-items: flex-start;

            &__top, &__title, .app-text-field {
                margin-bottom: 1rem;
            }
        }
    }
}
</style>
