<template lang="pug">
    .page.patients(:class="{'patients--more-filters-opened': moreFiltersOpened}")

        .patients-filters.forDesktop
            .row
                app-text-field.patients-filter--search(
                    v-model="search"
                    label="Поиск по ФИО / ОМС / ID"
                )
                app-select.patients-filter--disease(
                    v-model="disease"
                    :options="diseasesList"
                    clearable
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    label="Основное заболевание"
                )
                app-select.patients-filter--targeted-therapy(
                    v-model="targetTherapy"
                    :options="targetTherapyList"
                    clearable
                    multiple
                    track-by="name"
                    :allowEmpty="true"
                    searchable
                    label="Таргетная терапия"
                )
                app-more-btn(
                    :class="{'app-more-btn--active': moreFiltersOpened}"
                    @click="moreFiltersOpened=!moreFiltersOpened"
                ) Еще фильтры
                app-button(
                    title="Добавить пациента"
                    tag="router-link"
                    to="/patients/new"
                    large
                )
                    add-user-icon
                v-popover.patients-export(
                    trigger="hover"
                    placement="bottom-end"
                    :disabled="!isDisabledPatientsExport"
                )
                    app-button(
                        title="Выгрузка пациентов"
                        large
                        :loading="exportPatientsLoading"
                        :disabled="isDisabledPatientsExport"
                        @click="exportPatients"
                    )
                        download-icon
                    template(slot="popover")
                        span.patients-export__popover Скачивание доступно только администраторам

            .row(v-if="moreFiltersOpened")
                app-select.patients-filter--pathology(
                    v-model="pathology"
                    :options="pathologyList"
                    clearable
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    label="Патологии"
                )
                app-select.patients-filter--basic-therapy(
                    v-model="basicTherapy"
                    :options="basicTherapyList"
                    clearable
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    searchable
                    label="Базисная терапия"
                )
                app-datepicker.patients-filter--last-contact(
                    v-model="lastContact"
                    range
                    clearable
                    label="Последний контакт"
                )
                .app-choice-group
                    .app-choice-group__label Активность карты
                    .app-choice-group__list
                        app-choice(
                            v-model="mapActivityNo"
                            type="checkbox"
                            label="Нет"
                        )
                        app-choice(
                            v-model="mapActivityYes"
                            type="checkbox"
                            label="Да"
                        )
                .app-choice-group
                    .app-choice-group__label Анализ Alex
                    .app-choice-group__list
                        app-choice(
                            v-model="alexNo"
                            type="checkbox"
                            label="Нет"
                        )
                        app-choice(
                            v-model="alexYes"
                            type="checkbox"
                            label="Да"
                        )
                .app-choice-group
                    .app-choice-group__label Последний визит
                    .app-choice-group__list
                        app-choice(
                            v-model="lastVisitMore3monthsAgo"
                            type="checkbox"
                            label="Более 3х месяцев"
                        )

                app-button.patients-filter--reset(
                    large
                    color="red"
                    @click="reset"
                ) Сбросить фильтр

        .patients-filters.forMobile_or_p(:class="{'open-half': allFiltersOpenedOnMobile, 'open-full': allFiltersOpenedOnMobile && moreFiltersOpened}")
            .patients-filters__mobile-head
                .row
                    app-more-btn(
                        :class="{'app-more-btn--active': allFiltersOpenedOnMobile}"
                        @click="allFiltersOpenedOnMobile=!allFiltersOpenedOnMobile"
                    ) Фильтр
                    app-button(
                        tag="router-link"
                        to="/patients/new"
                        large
                    )
                        add-user-icon
                    v-popover.patients-export(
                        placement="bottom-end"
                        :disabled="!isDisabledPatientsExport"
                    )
                        app-button(
                            large
                            :loading="exportPatientsLoading"
                            :disabled="isDisabledPatientsExport"
                            @click="exportPatients"
                        )
                            download-icon
                        template(slot="popover")
                            span.patients-export__popover Скачивание доступно только администраторам

            .row
                .br.row
                app-text-field.patients-filter--search(
                    v-model="search"
                    label="Поиск по ФИО / ОМС / ID"
                )
                app-select.patients-filter--disease(
                    v-model="disease"
                    :options="diseasesList"
                    clearable
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    label="Основное заболевание"
                )
                app-select.patients-filter--targeted-therapy(
                    v-model="targetTherapy"
                    :options="targetTherapyList"
                    clearable
                    multiple
                    track-by="name"
                    :allowEmpty="true"
                    searchable
                    label="Таргетная терапия"
                )

            .row
                app-select.patients-filter--pathology(
                    v-model="pathology"
                    :options="pathologyList"
                    clearable
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    label="Патологии"
                )
                app-select.patients-filter--basic-therapy(
                    v-model="basicTherapy"
                    :options="basicTherapyList"
                    clearable
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    searchable
                    label="Базисная терапия"
                )
                app-datepicker.patients-filter--last-contact(
                    v-model="lastContact"
                    clearable
                    range
                    label="Последний контакт"
                )
                .app-choice-group.patients-filter--map-cctivity
                    .app-choice-group__label Активность карты
                    .app-choice-group__list
                        app-choice(
                            v-model="mapActivityNo"
                            type="checkbox"
                            label="Нет"
                        )
                        app-choice(
                            v-model="mapActivityYes"
                            type="checkbox"
                            label="Да"
                        )
                .app-choice-group.patients-filter--alex
                    .app-choice-group__label Анализ Alex
                    .app-choice-group__list
                        app-choice(
                            v-model="alexNo"
                            type="checkbox"
                            label="Нет"
                        )
                        app-choice(
                            v-model="alexYes"
                            type="checkbox"
                            label="Да"
                        )
                .app-choice-group.patients-filter--last-visit-more-3-months-ago
                    .app-choice-group__label Последний визит
                    .app-choice-group__list
                        app-choice(
                            v-model="lastVisitMore3monthsAgo"
                            type="checkbox"
                            label="Более 3х месяцев"
                        )

            .row.patients-filters__mobile-links
                app-link.app-link--dark-blue(
                    v-if="!moreFiltersOpened"
                    @click="moreFiltersOpened=true"
                ) Еще фильтры
                app-link.app-link--dark-blue(
                    v-else
                    @click="moreFiltersOpened=false"
                ) Свернуть фильтр
                app-link.app-link--red(@click="reset") Сбросить фильтр

        .patients-table.app-table
            app-loader(v-if="loading")
            .app-message-text(v-else-if="isEmpty(patients)") Пациенты не найдены
            template(v-else)
                .app-table__fixed
                    .app-table__head
                        .app-table__col
                            a(
                                @click="changeSortBy('last_name')"
                                :class="getSortByClasses('last_name')"
                            ) Пациент
                                arrow-triangle
                    .app-table__body
                        .app-table__row(v-for="patient in patients")
                            .app-table__col
                                b {{ getFIOAbbreviation(patient) }}
                                br
                                small {{ getPatientSex(patient) }}, {{ getPatientAge(patient) }}
                                    span.patients-table__status(v-if="!patient.is_active") &nbsp;Неактивная карта
                .app-table__scrolling
                    .app-table__head
                        .app-table__col Основное заболевание
                        .app-table__col Сопутствующие патологии
                        .app-table__col Таргетная терапия
                        .app-table__col Базисная терапия
                        .app-table__col
                            a(
                                @click="changeSortBy('last_contact')"
                                :class="getSortByClasses('last_contact')"
                            ) Последний контакт
                                arrow-triangle
                        .app-table__col
                    .app-table__body
                        .app-table__row(v-for="patient in patients")
                            .app-table__col(v-html="getPatientMainDisease(patient)")
                            .app-table__col(v-html="getPatientPathology(patient)")
                            .app-table__col(v-html="getPatientTargetTherapies(patient)")
                            .app-table__col
                                span(v-html="getPatientBasicTherapies(patient)")
                            .app-table__col
                                span.last-contact-date {{ getPatientLastContact(patient).date }}
                                span(v-if="checkPatientLastVisitMore3MonthsAgo(patient)" title="Последний визит более 3х месяцев")
                                    danger-icon.danger-icon
                                br
                                small {{ getPatientLastContact(patient).type }}
                            .app-table__col
                                router-link.app-table__button(
                                    title="Повторный контакт"
                                    :to="{name: 'PatientsReContact', params: { id: patient.id }}"
                                )
                                    plus-icon
                                router-link.app-table__button(
                                    title="Карточка пациента"
                                    :to="{name: 'PatientsDetail', params: { id: patient.id }}"
                                )
                                    paper-icon
                                router-link.app-table__button(
                                    title="Редактирование"
                                    :to="{name: 'PatientsEdit', params: { id: patient.id }}"
                                )
                                    edit-icon
                .patients-table__total Всего выбрано: <b>{{ total }}</b>


        .patients-bottom.grey-bg
            app-pagination(
                v-model="page"
                :count="pagesCount"
            )
            .patients-bottom__sort
                app-link.app-link--dark.app-link--normal(no-pointer-events) Показывать по
                app-select(
                    v-model="perPage"
                    :options="perPageList"
                )

</template>

<script>
import addUserIcon from '@/assets/images/icons/add_user.svg';
import downloadIcon from '@/assets/images/icons/download.svg';
import filtersIcon from '@/assets/images/icons/filters.svg';
import plusIcon from '@/assets/images/icons/plus.svg';
import editIcon from '@/assets/images/icons/edit.svg';
import dangerIcon from '@/assets/images/icons/danger.svg';
import paperIcon from '@/assets/images/icons/paper.svg';
import ArrowSelect from '@/assets/images/icons/arrow_select.svg';
import ArrowTriangle from '@/assets/images/icons/arrow_triangle.svg';
import AppPagination from '@/components/app/app-pagination/index';
import AppMoreBtn from '@/components/app/app-more-btn/index';
import moment from 'moment';
import { SEX } from '@/js/const';
import { CONTACT_TYPES } from '@/js/const';
import { isEmpty, debounce } from 'lodash';
import getFIOAbbreviation from '@/js/utils/getFIOAbbreviation';
import downloadFile from '@/js/utils/downloadFile';

export default {
    name: 'patients',

    components: {
        addUserIcon,
        downloadIcon,
        plusIcon,
        editIcon,
        dangerIcon,
        paperIcon,
        filtersIcon,
        ArrowSelect,
        ArrowTriangle,
        AppPagination,
        AppMoreBtn,
    },

    data: () => ({
        patients: [],
        total: 0,

        moreFiltersOpened: false,
        allFiltersOpenedOnMobile: false,

        search: '',
        disease: [],
        targetTherapy: [],
        pathology: [],
        basicTherapy: [],
        lastContact: '',
        mapActivityNo: false,
        mapActivityYes: true,
        alexNo: false,
        alexYes: false,
        lastVisitMore3monthsAgo: false,

        diseasesList: [
            {
                name: 'Атопический дерматит',
                id: 'DERMATITIS',
            },
            {
                name: 'Бронхиальная астма',
                id: 'ASTHMA',
            },
            {
                name: 'Крапивница',
                id: 'URTICARIA_MAIN',
            },
        ],
        targetTherapyList: [],
        pathologyList: [],
        basicTherapyList: [],

        sortBy: 'last_contact',
        order: 'descend', // ascend, descend
        page: 1,
        pagesCount: 0,
        perPage: {name: 50},
        perPageList: [
            {name: 10},
            {name: 50},
            {name: 100},
        ],

        loading: false,
        exportPatientsLoading: false,
    }),

    computed: {
        debouncedFetchPatients() {
            return debounce(() => {
                this.fetchPatients();
            }, 500);
        },

        role() {
            return this.$store.getters.role;
        },

        isDisabledPatientsExport() {
            return this.role !== 'ADMIN';
        },
    },

    watch: {
        page() {
            this.fetchPatients();
        },

        perPage() {
            this.page = 1;
            this.fetchPatients();
        },

        search() {
            this.page = 1;
            if (this.search) {
                this.debouncedFetchPatients();
            } else {
                this.fetchPatients();
            }
        },
        disease() {
            this.page = 1;
            this.fetchPatients();
        },
        targetTherapy() {
            this.page = 1;
            this.fetchPatients();
        },
        pathology() {
            this.page = 1;
            this.fetchPatients();
        },
        basicTherapy() {
            this.page = 1;
            this.fetchPatients();
        },
        lastContact() {
            this.page = 1;
            this.fetchPatients();
        },
        mapActivityNo() {
            this.page = 1;
            this.fetchPatients();
        },
        mapActivityYes() {
            this.page = 1;
            this.fetchPatients();
        },
        alexNo() {
            this.page = 1;
            this.fetchPatients();
        },
        alexYes() {
            this.page = 1;
            this.fetchPatients();
        },
        lastVisitMore3monthsAgo() {
            this.page = 1;
            this.fetchPatients();
        },
    },

    created() {
        this.fetchSearchFilters();
        this.fetchPatients();
    },

    methods: {
        async fetchPatients() {
            if (this.loading) return;
            this.loading = true;
            try {
                const { data } = await this.axios.get('patients'+this.getQuery());
                this.patients = data?.data;
                this.pagesCount = data?.last_page;
                this.total = data?.total;
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },

        getQuery() {
            let query = `?order=${this.order}
                &sortBy=${this.sortBy}
                &perPage=${this.perPage.name}
                &page=${this.page}`;

            if (this.search) {
                query += `&q=${this.search}`;
            }

            if (this.disease.length) {
                this.disease.forEach(d => {
                    query += `&main_sickness[]=${d.id}`;
                });
            }

            if (this.targetTherapy.length) {
                this.targetTherapy.forEach(therapy => {
                    therapy.items.forEach(t => {
                        query += `&target_therapies[]=${t.id}`;
                    });
                });
            }

            if (this.pathology.length) {
                this.pathology.forEach(s => {
                    query += `&other_sickness[]=${s.id}`;
                });
            }

            if (this.basicTherapy.length) {
                this.basicTherapy.forEach(t => {
                    query += `&basis_therapies[]=${t.id}`;
                });
            }

            if (this.lastContact) {
                query += `&last_contact_from=${moment(this.lastContact[0]).format('YYYY-MM-DD')}`;
                query += `&last_contact_to=${moment(this.lastContact[1]).format('YYYY-MM-DD')}`;
            }
            if (this.mapActivityNo && !this.mapActivityYes) {
                query += `&is_active=0`;
            }
            if (this.mapActivityYes && !this.mapActivityNo) {
                query += `&is_active=1`;
            }
            if (this.alexNo && !this.alexYes) {
                query += `&alex=0`;
            }
            if (this.alexYes && !this.alexNo) {
                query += `&alex=1`;
            }
            if (this.lastVisitMore3monthsAgo) {
                query += `&last_contact_from=${moment(0).format('YYYY-MM-DD')}`;
                query += `&last_contact_to=${moment().subtract(3, 'months').format('YYYY-MM-DD')}`;
            }
            return query;
        },

        async fetchSearchFilters() {
            const pathologyTypes = {
                ALLERGIC_RHINITIS: 'Аллергический ринит',
                ALLERGIC_CONJUNCTIVITIS: 'Аллергический конъюнктивит',
                POLYPOID_RHINOSINUSITIS: 'Полипозный риносинусит',
                GERB: 'ГЭРБ',
                SIDE_DERMATITIS: 'Атопический дерматит',
                SIDE_ASTHMA: 'Бронхиальная астма',
                CHRONIC_URTICARIA: 'Крапивница',
                ANGIOOTEK: 'Ангиоотеки',
            };
            try {
                const { data } = await this.axios.get('patients/searchfilters');
                this.targetTherapyList = data.targetTherapies.reduce((total, t) => {
                    const item = total.find(tt => tt.name === t.name);
                    if (item) {
                        item.items.push(t);
                    } else {
                        total.push({
                            name: t.name,
                            items: [t],
                        });
                    }
                    return total;
                }, []);
                this.pathologyList = data.otherSickness.map(o => ({
                    name: pathologyTypes[o.type],
                    id: o.type,
                }));
                this.basicTherapyList = data.basisTherapies.map(b => ({
                    ...b,
                    name: b.chemistry_name,
                }));
            } catch (e) {
                console.log(e);
            }
        },

        reset() {
            this.search = '';
            this.disease = [];
            this.targetTherapy = [];
            this.pathology = [];
            this.basicTherapy = [];
            this.lastContact = '';
            this.mapActivityNo = false;
            this.mapActivityYes = true;
            this.alexNo = false;
            this.alexYes = false;
            this.lastVisitMore3monthsAgo = false;
        },

        changeSortBy(param) {
            if (this.sortBy !== param) {
                this.order = 'ascend';
            } else {
                this.order = this.order === 'ascend' ? 'descend' : 'ascend';
            }
            this.sortBy = param;
            this.page = 1;
            this.fetchPatients();
        },

        getSortByClasses(param) {
            return {
                active: this.sortBy === param,
                reverse: this.sortBy === param && this.order === 'descend',
            };
        },

        getPatientSex(patient) {
            return SEX[patient.sex];
        },

        getPatientAge(patient) {
            return moment().diff(patient.birth_date, 'years');
        },

        getPatientMainDisease(patient) {
            const diseases = [
                {
                    id: 'DERMATITIS',
                    name: 'АД',
                },
                {
                    id: 'ASTHMA',
                    name: 'БА',
                },
                {
                    id: 'URTICARIA_MAIN',
                    name: 'КП',
                },
            ];
            return diseases
                .filter(d => patient.sicknesses.some(s => s.type === d.id))
                .map(d => d.name)
                .join(', ');
        },

        getPatientPathology(patient) {
            const pathology = [
                {
                    id: 'ALLERGIC_RHINITIS',
                    name: 'Аллергический ринит',
                    shortName: 'Ринит',
                },
                {
                    id: 'ALLERGIC_CONJUNCTIVITIS',
                    name: 'Аллергический конъюнктивит',
                    shortName: 'Конъюнктивит',
                },
                {
                    id: 'POLYPOID_RHINOSINUSITIS',
                    name: 'Полипозный риносинусит',
                    shortName: 'Риносинусит',
                },
                {
                    id: 'GERB',
                    name: 'ГЭРБ',
                    shortName: 'ГЭРБ',
                },
                {
                    id: 'SIDE_DERMATITIS',
                    name: 'Атопический дерматит',
                    shortName: 'АД',
                },
                {
                    id: 'SIDE_ASTHMA',
                    name: 'Бронхиальная астма',
                    shortName: 'БА',
                },
                {
                    id: 'CHRONIC_URTICARIA',
                    name: 'Хроническая крапивница',
                    shortName: 'КП',
                },
                {
                    id: 'ANGIOOTEK',
                    name: 'Ангиоотеки',
                    shortName: 'Ангиоотеки',
                },
            ];
            const filteredPathology = pathology
                .filter(d => patient.sicknesses.some(s => s.type === d.id))
                .map(d => d.shortName);
            return filteredPathology
                .slice(0, 3)
                .join(', ') + (filteredPathology.length > 3 ? ` ... (${filteredPathology.length - 3})` : '');
        },

        getPatientTargetTherapies(patient) {
            const therapies = [
                ...patient.sicknesses.find(s => s.type === 'DERMATITIS')?.target_therapies || [],
                ...patient.sicknesses.find(s => s.type === 'ASTHMA')?.target_therapies || [],
                ...patient.sicknesses.find(s => s.type === 'URTICARIA_MAIN')?.target_therapies || [],
            ]
                .filter(t => !t.end_at)
                .map(t => t.drug?.name);
            return therapies
                .slice(0, 3)
                .join(', ') + (therapies.length > 3 ? ` ... (${therapies.length - 3})` : '');
        },

        getPatientBasicTherapies(patient) {
            let therapies = [
                ...patient.sicknesses.find(s => s.type === 'DERMATITIS')?.basis_therapies || [],
                ...patient.sicknesses.find(s => s.type === 'ASTHMA')?.basis_therapies || [],
                ...patient.sicknesses.find(s => s.type === 'URTICARIA_MAIN')?.basis_therapies || [],
            ]
                .filter(t => !t.end_at)
                .map(t => t.medicine.chemistry_name);
            let splitedTherapies = therapies
                .join(', ')
                .split(' ');
            return splitedTherapies
                .slice(0, 7)
                .join(' ')
                .replace(/,$/, '') + (splitedTherapies.length > 7 ? '&nbsp;...' : '');
        },

        getPatientLastContact(patient){
            let lastContact = patient.contacts[0];
            patient.contacts.forEach(c => {
                if (+moment(c.date_at) >= +moment(lastContact?.date_at)) {
                    lastContact = c;
                }
            });
            return {
                date: moment(lastContact?.date_at).format('DD.MM.YYYY'),
                type: CONTACT_TYPES.find(c => c.id === lastContact?.contact_type)?.name,
            };
        },

        checkPatientLastVisitMore3MonthsAgo(patient) {
            return moment(patient.last_contact_date) < moment().subtract(3, 'months');
        },

        async exportPatients() {
            if (this.exportPatientsLoading) return;

            this.exportPatientsLoading = true;
            try {
                const { data } = await this.axios.get('patients/export'+this.getQuery(), { responseType: 'blob' });
                downloadFile(data, 'Пациенты');
            } catch (e) {
                console.log(e);
            } finally {
                this.exportPatientsLoading = false;
            }
        },

        getFIOAbbreviation,

        isEmpty,
    },
};
</script>

<style lang="scss" scoped>
.patients {
    padding-top: rem(16px);

    &-filters {
        margin-bottom: rem(16px);
        padding: rem(16px) 0 0 rem(16px);

        background: $white;
        border-radius: rem(8px);
        box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);

        .patients-filter {
            &--disease, &--targeted-therapy, &--basic-therapy, &--last-contact  {
                flex-shrink: 0;

                width: rem(246px);
            }

            &--pathology {
                width: rem(300px);
            }

            &--reset {
                margin-left: auto;
            }
        }
    }

    &-table {
        position: relative;

        min-height: calc(100vh - #{rem(86px) + rem(16px) + rem(88px) + rem(16px) + rem(88px)});

        .app-table__fixed .app-table__col {
            flex-shrink: 0;

            width: 14rem;
        }

        .app-table__scrolling .app-table__col {
            &:nth-of-type(1) {
                width: 18%;
            }

            &:nth-of-type(2) {
                width: 18.4%;
            }

            &:nth-of-type(3) {
                width: 18.3%;
            }

            &:nth-of-type(4) {
                width: 18.3%;

                span {
                    display: -webkit-box;

                    overflow: hidden;

                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
            }

            &:nth-of-type(5) {
                width: 14.4%;
            }

            &:nth-of-type(6) {
                flex-shrink: 0;

                width: 10rem;
            }
        }

        &__total {
            position: absolute;
            top: rem(8px);
            right: 0;

            font-size: rem(12px);
            color: $dark-blue;

            b {
                font-size: rem(16px);
            }
        }

        &__status {
            color: $red;
        }
    }

    &-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1;

        display: flex;
        justify-content: space-between;

        padding: rem(16px) 0;

        &__sort {
            .app-link, .app-select {
                margin-bottom: 0;
            }

            .app-select {
                width: rem(96px);
                margin-right: 0;
            }
        }
    }

    &--more-filters-opened &-table {
        min-height: calc(100vh - #{rem(86px) + rem(16px) + rem(160px) + rem(16px) + rem(88px)});
    }

    &-export {
        margin: 0 1rem 1rem 0;

       .app-button {
           margin: 0;
       }

        &__popover {
            color: $red;
        }

        @include mobile_or_P {
            margin: 0 0 0 1rem;

            &__popover {
                font-size: rem(12px);
                color: $red;
            }
        }
    }

    .danger-icon {
        width: rem(20px);

        vertical-align: middle;
    }

    .last-contact-date {
        display: inline-block;

        width: rem(96px);

        @include mobile_or_P {
            width: rem(86px);
        }
    }

    @include mobile_or_P {
        padding-top: 1px;
        padding-bottom: rem(90px);

        &-filters {
            height: rem(72px);
            padding: 0;
            overflow: hidden;

            border-radius: 0;

            .row {
                padding: 0 1rem;

                &:nth-of-type(3) {
                    height: 0;
                    overflow: hidden;
                }
            }

            &.open-half {
                height: initial;
                overflow: initial;
            }

            &.open-full {
                height: initial;
                overflow: initial;

                .row:nth-of-type(3) {
                    height: initial;
                    overflow: initial;
                }
            }

            .patients-filters__mobile-head {
                box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);

                .row {
                    padding: rem(8px) 0 rem(8px) rem(16px);
                }

                .app-more-btn, .app-button {
                    margin-bottom: 0;
                }

                .app-more-btn {
                    width: rem(184px);
                }
            }

            .patients-filters__mobile-links {
                display: flex;
                justify-content: space-between;

                width: 100%;

                .app-link {
                    height: initial;
                    margin-bottom: rem(24px);
                }
            }

            .patients-filter {
                &--disease, &--targeted-therapy, &--basic-therapy, &--last-contact, &--pathology {
                    width: 100%;
                }

                &--map-cctivity,
                &--alex,
                &--last-visit-more-3-months-ago {
                    .app-choice-group__label {
                        flex-shrink: 0;

                        width: rem(135px);
                    }
                }
            }

            .app-choice-group {
                flex-direction: row;
                align-items: center;

                &__label {
                    margin-right: 1.5rem;
                }
            }
        }

        &-table {
            min-height: initial;

            .app-table__fixed .app-table__col {
                width: 9.9rem;
            }

            .app-table__scrolling {
                .app-table__body, .app-table__head {
                    width: 53rem;
                }

                .app-table__col {
                    flex-shrink: 0;

                    &:nth-of-type(1) {
                        width: 6rem;
                    }

                    &:nth-of-type(2) {
                        width: 11rem;
                    }

                    &:nth-of-type(3) {
                        width: 8rem;
                    }

                    &:nth-of-type(4) {
                        width: 8rem;
                    }

                    &:nth-of-type(5) {
                        width: 10rem;
                    }
                }
            }

            &__total {
                display: none;
            }

            &__status {
                display: block;
            }
        }

        &-bottom {
            position: fixed;
            left: 0;

            width: 100%;
            padding: 1rem 0;

            &__sort {
                display: none;
            }
        }

        &--more-filters-opened &-table {
            min-height: initial;
        }

        @include landscape {
            padding-bottom: rem(80px);

            &-bottom {
                padding: rem(10px) 0;
            }
        }
    }
}
</style>
